import axios from "axios";
import {
    GET_INDUSTRIES_START,
    GET_INDUSTRIES,
    GET_INDUSTRIES_ERROR,
} from "./constants";
import { ThunkResult } from "../../store";
import Industry from "../../types/Industry";
import _ from "lodash";

type ServerResponse = {
    data: SuccessResponse | FailureResponse;
};

type SuccessResponse = {
    status: "SUCCESS";
    industries: Industry[];
};

type FailureResponse = {
    status: "ERROR";
    message: string;
};

type getIndustriesStart = { type: typeof GET_INDUSTRIES_START };

type getIndustries = {
    type: typeof GET_INDUSTRIES;
    payload: {
        industries: Industry[];
    };
};

type getIndustriesError = {
    type: typeof GET_INDUSTRIES_ERROR;
    payload: {
        error: string;
    };
};

export function getIndustries(): ThunkResult<void> {
    return async (dispatch, getState) => {
        dispatch<getIndustriesStart>({ type: GET_INDUSTRIES_START });
        const { token, loggedIn } = getState().authentication;

        if (!loggedIn) {
            return;
        }

        try {
            const response: ServerResponse = await axios({
                url: process.env.REACT_APP_API + "/api/industries",
                method: "POST",
                headers: {
                    accesstoken: token,
                },
            });

            const { data } = response;

            if (data.status === "SUCCESS") {
                const industries = _.sortBy(data.industries, ["name"]);

                dispatch<getIndustries>({
                    type: GET_INDUSTRIES,
                    payload: { industries },
                });
            } else {
                dispatch<getIndustriesError>({
                    type: GET_INDUSTRIES_ERROR,
                    payload: { error: data.message },
                });
            }
        } catch (error) {
            dispatch<getIndustriesError>({
                type: GET_INDUSTRIES_ERROR,
                payload: { error: (error as any).toJSON().message },
            });
        }
    };
}

export type industriesActions =
    | getIndustriesStart
    | getIndustries
    | getIndustriesError;
