import React, { FunctionComponent } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../reducers/authentication/actions";
import Logo from "./logo.png";
import { ReactComponent as SignOut } from "./sign-out.svg";
import { TextField } from "@material-ui/core";
import { setBrandFilter } from "../../reducers/promotions/actions";

const StyledHeader = styled.header`
  z-index: 9999;
  position: relative;
  height: 80px;

  button span {
    text-transform: initial;
  }
`;

const StyledAppBar = styled(AppBar)`
  padding: 15px 30px 15px 50px;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

  > div {
    min-height: initial;
  }
`;

const StyledLogo = styled.div`
  flex-grow: 1;
  letter-spacing: 0.25px;

  img {
    width: 180px;
    height: 50px;
  }
`;

const StyledSignOut = styled(SignOut)`
  display: inline-flex;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  color: #286dcb;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
`;

const StyledTextField = styled(TextField)`
  font-size: 14px;
  line-height: 16px;
  color: #525f81;
  width: 447px;
  margin-right: 33px;

  input {
    padding: 14px 12px !important;
  }

  input::placeholder {
    font-weight: 400;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

const Header: FunctionComponent = () => {
  const dispatch = useDispatch();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    dispatch(logout());
  };

  return (
    <StyledHeader>
      <StyledAppBar position="fixed" color="transparent">
        <Toolbar disableGutters>
          <StyledLogo>
            <Link to="/">
              <img src={Logo} alt="Modvise" />
            </Link>
          </StyledLogo>
          <StyledTextField
            variant="filled"
            placeholder={"Find brand (beta)"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(setBrandFilter(e.target.value))
            }
          />
          <StyledLink to="/" onClick={(e) => handleClick(e)}>
            <StyledSignOut />
            Log out
          </StyledLink>
        </Toolbar>
      </StyledAppBar>
      <Toolbar />
    </StyledHeader>
  );
};

export default Header;
